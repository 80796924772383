import React from "react";

const CareerContent = () => {

    return  <>
        {/* ========== END HEADER ========== */}
        {/* ========== MAIN CONTENT ========== */}
        <div className="content-space-t-2 content-space-t-lg-2">
            {/* Hero */}
            <div
                className="d-flex bg-img-center"
                style={{ backgroundImage: "url(/assets/img/1920x800/img2.jpg)" }}
            >
                <div className="container d-flex justify-content-end flex-column min-vh-lg-75 content-space-1 content-space-lg-b-3">
                    <div className="w-lg-60" style={{marginBottom:90}}>
                        <h1 className="display-4 text-white">Venez travailler avec nous</h1>
                        <p className="lead text-white">Ensemble nous ferrons des merveilles</p>
                    </div>
                </div>
            </div>
            {/* End Hero */}
            {/* Card Grid */}
            <div className="container content-space-t-1 content-space-t-lg-3">
                {/* Heading */}
                <div className="w-lg-65 text-center mx-lg-auto mb-5 mb-sm-7 mb-lg-10">
                    <h2>Tirez le meilleur parti de votre carrière</h2>
                    <p>Rejoignez les personnes qui créent un guichet unique pour la liberté financière.</p>
                </div>
                {/* End Heading */}
                <div className="row row-cols-2 row-cols-lg-4 gx-2 gx-md-4">
                    <div className="col mb-2 mb-md-4">
                        {/* Card */}
                        <a
                            className="card card-borderless card-transition-zoom bg-soft-primary h-100"
                            href="#"
                        >
                            <div className="card-transition-zoom-item">
                                <img
                                    className="card-img"
                                    src="/assets/svg/components/card-7.svg"
                                    alt="Image Description"
                                />
                            </div>
                            <div className="card-body">
                {/*<span className="card-subtitle text-body">*/}
                {/*  <span className="fw-bold text-dark">6</span> positions*/}
                {/*</span>*/}
                                <h4 className="card-title">Administration</h4>
                            </div>
                        </a>
                        {/* End Card */}
                    </div>
                    {/* End Col */}
                    <div className="col mb-2 mb-md-4">
                        {/* Card */}
                        <a
                            className="card card-borderless card-transition-zoom bg-soft-primary h-100"
                            href="#"
                        >
                            <div className="card-transition-zoom-item">
                                <img
                                    className="card-img"
                                    src="/assets/svg/components/card-8.svg"
                                    alt="Image Description"
                                />
                            </div>
                            <div className="card-body">
                {/*<span className="card-subtitle text-body">*/}
                {/*  <span className="fw-bold text-dark">2</span> positions*/}
                {/*</span>*/}
                                <h4 className="card-title">Comptabilité</h4>
                            </div>
                        </a>
                        {/* End Card */}
                    </div>
                    {/* End Col */}
                    <div className="col mb-2 mb-md-4">
                        {/* Card */}
                        <a
                            className="card card-borderless card-transition-zoom bg-soft-primary h-100"
                            href="#"
                        >
                            <div className="card-transition-zoom-item">
                                <img
                                    className="card-img"
                                    src="/assets/svg/components/card-10.svg"
                                    alt="Image Description"
                                />
                            </div>
                            <div className="card-body">
                {/*<span className="card-subtitle text-body">*/}
                {/*  <span className="fw-bold text-dark">19</span> positions*/}
                {/*</span>*/}
                                <h4 className="card-title">Commercial et Communication</h4>
                            </div>
                        </a>
                        {/* End Card */}
                    </div>
                    {/* End Col */}
                    <div className="col mb-2 mb-md-4">
                        {/* Card */}
                        <a
                            className="card card-borderless card-transition-zoom bg-soft-primary h-100"
                            href="#"
                        >
                            <div className="card-transition-zoom-item">
                                <img
                                    className="card-img"
                                    src="/assets/svg/components/card-9.svg"
                                    alt="Image Description"
                                />
                            </div>
                            <div className="card-body">
                {/*<span className="card-subtitle text-body">*/}
                {/*  <span className="fw-bold text-dark">25</span> positions*/}
                {/*</span>*/}
                                <h4 className="card-title">Informatique</h4>
                            </div>
                        </a>
                        {/* End Card */}
                    </div>
                    {/* End Col */}
                </div>
                {/* End Row */}
            </div>
            {/* End Card Grid */}
            {/* Step Features */}
            <div className="container content-space-1 content-space-lg-3">
                {/* Heading */}
                <div className="w-lg-65 text-center mx-lg-auto mb-5 mb-sm-7 mb-lg-10">
                    <h2>Comment nous embauchons notre équipe</h2>
                    <p>
                        Nous aimons garder les choses aussi simples que possible afin que nous puissions accéder à ce qui est
                        vraiment important - en savoir plus sur vous.
                    </p>
                </div>
                {/* End Heading */}
                <div className="w-lg-75 mx-lg-auto">
                    {/* List Timeline */}
                    <ul className="list-unstyled list-timeline list-py-3">
                        <li className="list-timeline-item">
                            {/* Card */}
                            <div className="card card-lg">
                                <div className="card-body">
                                    {/* Media */}
                                    <div className="d-flex">
                                        <div className="flex-shrink-0">
                                            <i className="bi-file-earmark-check text-dark fs-2" />
                                        </div>
                                        <div className="flex-grow-1 ms-4">
                                            <h4 className="card-title">
                                                <span className="fs-2 text-primary">01.</span> Application
                                                et écran recruteur
                                            </h4>
                                            <p className="card-text">
                                                Le début de notre processus de candidature est le même pour tous
                                                de nos rôles. Nous voulons en savoir un peu plus sur vous à travers une
                                                conversation décontractée.
                                            </p>
                                        </div>
                                    </div>
                                    {/* End Media */}
                                </div>
                            </div>
                            {/* End Card */}
                        </li>
                        <li className="list-timeline-item ms-auto">
                            {/* Card */}
                            <div className="card card-lg">
                                <div className="card-body">
                                    {/* Media */}
                                    <div className="d-flex">
                                        <div className="flex-shrink-0">
                                            <i className="bi-clock-history text-dark fs-2" />
                                        </div>
                                        <div className="flex-grow-1 ms-4">
                                            <h4 className="card-title">
                                                <span className="fs-2 text-primary">02.</span> Premier tour
                                                interview
                                            </h4>
                                            <p className="card-text">
                                                Nous aimons nous connecter avec des gens qui croient qu'ils feront un
                                                différence. A ce stade, un entretien nous aidera à la fois
                                                décider si l'intérêt est mutuel.
                                            </p>
                                        </div>
                                    </div>
                                    {/* End Media */}
                                </div>
                            </div>
                            {/* End Card */}
                        </li>
                        <li className="list-timeline-item">
                            {/* Card */}
                            <div className="card card-lg">
                                <div className="card-body">
                                    {/* Media */}
                                    <div className="d-flex">
                                        <div className="flex-shrink-0">
                                            <i className="bi-pencil-square text-dark fs-2" />
                                        </div>
                                        <div className="flex-grow-1 ms-4">
                                            <h4 className="card-title">
                                                <span className="fs-2 text-primary">03.</span> Tâche ou
                                                défi
                                            </h4>
                                            <p className="card-text">
                                                Il est maintenant temps d'appliquer votre réflexion à une vie réelle
                                                situations. Qu'il s'agisse de passer un test technique,
                                                participer à un jeu de rôle ou préparer une présentation pour
                                                relever un défi - nous voulons vous voir en action.
                                            </p>
                                        </div>
                                    </div>
                                    {/* End Media */}
                                </div>
                            </div>
                            {/* End Card */}
                        </li>
                        <li className="list-timeline-item ms-auto">
                            {/* Card */}
                            <div className="card card-lg">
                                <div className="card-body">
                                    {/* Media */}
                                    <div className="d-flex">
                                        <div className="flex-shrink-0">
                                            <i className="bi-hand-thumbs-up text-dark fs-2" />
                                        </div>
                                        <div className="flex-grow-1 ms-4">
                                            <h4 className="card-title">
                                                <span className="fs-2 text-primary">04.</span> Final
                                                entretien et offre
                                            </h4>
                                            <p className="card-text">
                                                Une fois que vous avez rencontré l'équipe et que nous avons appris à nous connaître, il est temps pour nous de décider si nous sommes compatibles.
                                            </p>
                                        </div>
                                    </div>
                                    {/* End Media */}
                                </div>
                            </div>
                            {/* End Card */}
                        </li>
                    </ul>
                    {/* End List Timeline */}
                </div>
            </div>
            {/* End Step Features */}
            {/*/!* Heading *!/*/}
            {/*<div className="container">*/}
            {/*    <div className="w-lg-65 text-center mx-lg-auto mb-5 mb-sm-7 mb-lg-10">*/}
            {/*        <h2>Découvrez nos bureaux</h2>*/}
            {/*    </div>*/}
            {/*</div>*/}
            {/*/!* End Heading *!/*/}
            {/*/!* Sliding Image *!/*/}
            {/*<div className="sliding-img mb-3">*/}
            {/*    <div*/}
            {/*        className="sliding-img-frame-to-start"*/}
            {/*        style={{ backgroundImage: "url(./assets/img/others/img1.png)" }}*/}
            {/*    />*/}
            {/*</div>*/}
            {/*/!* End Sliding Image *!/*/}
            {/*/!* Sliding Image *!/*/}
            {/*<div className="sliding-img mb-3">*/}
            {/*    <div*/}
            {/*        className="sliding-img-frame-to-start-sm"*/}
            {/*        style={{ backgroundImage: "url(./assets/img/others/img2.png)" }}*/}
            {/*    />*/}
            {/*</div>*/}
            {/*/!* End Sliding Image *!/*/}
            {/*/!* Icon Blocks *!/*/}
            <div className="container content-space-1 content-space-lg-3">
                {/* Heading */}
                <div className="w-lg-65 text-center mx-lg-auto mb-5 mb-sm-7 mb-lg-10">
                    <h2>Notre engagement RH</h2>
                    <p>Vous voulez nous rejoindre ? Découvrez les deux ingrédients d’une rencontre réussie !</p>
                    <ul className="list-checked list-checked-soft-bg-primary list-checked-lg mb-5">
                        <li>Apprendre, se cultiver et grandir tous les jours</li>
                        <li>S’ouvrir aux autres et créer des liens qui comptent</li>
                    </ul>
                </div>
                {/* End Heading */}
                <div className="row justify-content-lg-between">
                    <div className="col-sm-6 col-lg-5 mb-5">
                        {/* Icon Block */}
                        <div className="d-flex">
                            <div className="flex-shrink-0">
                                <i className="bi-circle-square fs-1 text-dark" />
                            </div>
                            <div className="flex-grow-1 ms-5">
                                <h5>Aller mieux</h5>
                                <p>
                                    Nous embrassons les deux pour apprendre, évoluer et grandir en permanence en tant que
                                    équipe.
                                </p>
                            </div>
                        </div>
                        {/* End Icon Block */}
                    </div>
                    {/* End Col */}
                    <div className="col-sm-6 col-lg-5 mb-5">
                        {/* Icon Block */}
                        <div className="d-flex">
                            <div className="flex-shrink-0">
                                <i className="bi-cup fs-1 text-dark" />
                            </div>
                            <div className="flex-grow-1 ms-5">
                                <h5>Être gentil</h5>
                                <p>
                                    Être gentil nous rend plus forts. Nous sommes humbles, authentiques et traitons
                                    les uns les autres avec respect. Nous faisons confiance et supposons toujours
                                    intention positive.
                                </p>
                            </div>
                        </div>
                        {/* End Icon Block */}
                    </div>
                    {/* End Col */}
                    <div className="col-sm-6 col-lg-5 mb-5">
                        {/* Icon Block */}
                        <div className="d-flex">
                            <div className="flex-shrink-0">
                                <i className="bi-bar-chart fs-1 text-dark" />
                            </div>
                            <div className="flex-grow-1 ms-5">
                                <h5>Générez des résultats</h5>
                                <p>
                                    L'excellence de chacun de nous est requise pour notre succès commun.
                                    Nous nous concentrons sur les résultats et travaillons avec un sentiment d'appartenance et
                                    urgence.
                                </p>
                            </div>
                        </div>
                        {/* End Icon Block */}
                    </div>
                    {/* End Col */}
                    <div className="col-sm-6 col-lg-5 mb-5">
                        {/* Icon Block */}
                        <div className="d-flex">
                            <div className="flex-shrink-0">
                                <i className="bi-check2-circle fs-1 text-dark" />
                            </div>
                            <div className="flex-grow-1 ms-5">
                                <h5>Rester simple</h5>
                                <p>
                                    Simple n'est pas synonyme de facile. Nous sommes intentionnels et concentrés - nous
                                    croire en la qualité plutôt qu'en la quantité. Nous réduisons les idées et les solutions
                                    à leurs options les plus harmonieuses et élégantes.
                                </p>
                            </div>
                        </div>
                        {/* End Icon Block */}
                    </div>
                    {/* End Col */}
                    <div className="col-sm-6 col-lg-5 mb-5 mb-sm-0">
                        {/* Icon Block */}
                        <div className="d-flex">
                            <div className="flex-shrink-0">
                                <i className="bi-bell fs-1 text-dark" />
                            </div>
                            <div className="flex-grow-1 ms-5">
                                <h5>Les clients d'abord</h5>
                                <p>
                                    Notre travail le plus important est d'aider nos clients à réussir.
                                    Nous sommes soucieux du détail et gagnons leur confiance grâce à notre
                                    expertise, intégrité et fiabilité.
                                </p>
                            </div>
                        </div>
                        {/* End Icon Block */}
                    </div>
                    {/* End Col */}
                    <div className="col-sm-6 col-lg-5">
                        {/* Icon Block */}
                        <div className="d-flex">
                            <div className="flex-shrink-0">
                                <i className="bi-bicycle fs-1 text-dark" />
                            </div>
                            <div className="flex-grow-1 ms-5">
                                <h5>Vacances illimitées</h5>
                                <p>
                                    Tout le monde a besoin d'une vie saine, heureuse et productive. Ça signifie
                                    vous aurez besoin de temps pour vous reposer et récupérer sans vacances restrictives
                                    limites.
                                </p>
                            </div>
                        </div>
                        {/* End Icon Block */}
                    </div>
                    {/* End Col */}
                </div>
                {/* End Row */}
            </div>
            {/* End Icon Blocks */}
            {/* Testimonials */}
            <div className="bg-soft-dark-to-start-75 overflow-hidden">
                <div className="container content-space-1">
                    <div className="row justify-content-lg-between align-items-md-center">
                        <div className="col-md-6 col-lg-5 mb-10 mb-lg-0">
                            <div className="position-relative">
                                <img
                                    className="img-fluid"
                                    src="/assets/img/500x650/img1.jpg"
                                    alt="Image Description"
                                />
                                {/* SVG Shape */}
                                <figure
                                    className="position-absolute bottom-0 start-0 zi-n1 mb-n7 ms-n7"
                                    style={{ width: "10rem" }}
                                >
                                    <img
                                        className="img-fluid"
                                        src="/assets/svg/components/dots.svg"
                                        alt="Image Description"
                                    />
                                </figure>
                                {/* End SVG Shape */}
                            </div>
                        </div>
                        {/* End Col */}
                        <div className="col-md-6">
                            {/* Blockquote */}
                            <figure className="blockquote-lg mb-7">
                                <span className="text-cap text-muted">Client vedette</span>
                                <blockquote className="blockquote">
                                    <em>
                                        Dire que le passage à OHADA a changé la vie est un
                                        euphémisme. Mon entreprise a doublé en efficacité et j'ai retrouvé le temps perdu.
                                    </em>
                                </blockquote>
                                <figcaption className="blockquote-footer">
                                    Fatim Mariko
                                    <span className="blockquote-footer-source">
                  CEO &amp; Risk | Doniya
                  </span>
                                </figcaption>
                            </figure>
                            {/* End Blockquote */}
                            {/*<a className="btn btn-dark" href="#">*/}
                            {/*    lis l'histoire*/}
                            {/*</a>*/}
                        </div>
                        {/* End Col */}
                    </div>
                    {/* End Row */}
                </div>
            </div>
            {/* End Testimonials */}
            {/* List Directory */}
            <div
                id="openingsSection"
                className="container content-space-t-2 content-space-lg-3 content-space-b-1"
            >
                {/* Heading */}
                <div className="w-lg-65 text-center mx-lg-auto mb-5 mb-sm-7 mb-lg-10">
                    <h2>Explorez les opportunités</h2>
                    <p>
                        Nous recherchons des personnes passionnées, motivées et audacieuses pour
                        rejoins notre équipe.
                    </p>
                </div>
                {/* End Heading */}

          {/*      <div className="row align-items-sm-center">*/}
          {/*          <div className="col-sm mb-3">*/}
          {/*              <h5 className="mb-0">offres</h5>*/}
          {/*          </div>*/}
          {/*          /!* End Col *!/*/}
          {/*          /!* <div className="col-sm-auto mb-3">*/}
          {/*  <button type="button" className="btn btn-white">*/}
          {/*    <i className="bi-bell me-1" /> Create alert*/}
          {/*  </button>*/}
          {/*</div> *!/*/}
          {/*          /!* End Col *!/*/}
          {/*      </div>*/}
          {/*      /!* End Row *!/*/}
          {/*      <div className="d-grid gap-3">*/}
          {/*          /!* Card *!/*/}
          {/*          <a className="card" href="#">*/}
          {/*              <div className="card-body">*/}
          {/*                  <div className="row">*/}
          {/*                      <div className="col-md-4 mb-2 mb-md-0">*/}
          {/*                          <span className="h6">Chargé de compte commercial</span>*/}
          {/*                      </div>*/}
          {/*                      /!* End Col *!/*/}
          {/*                      <div className="col-sm-5 col-md-2 mb-2 mb-sm-0">*/}
          {/*                          <span className="text-body">London</span>*/}
          {/*                      </div>*/}
          {/*                      /!* End Col *!/*/}
          {/*                      <div className="col-sm-5 col-md-4 mb-2 mb-sm-0">*/}
          {/*                          <span className="text-body">Développement de logiciels</span>*/}
          {/*                      </div>*/}
          {/*                      /!* End Col *!/*/}
          {/*                      <div className="col-sm-2 text-sm-end">*/}
          {/*                          <span className="link link-pointer">Appliquer</span>*/}
          {/*                      </div>*/}
          {/*                      /!* End Col *!/*/}
          {/*                  </div>*/}
          {/*                  /!* End Row *!/*/}
          {/*              </div>*/}
          {/*          </a>*/}
          {/*          /!* End Card *!/*/}
          {/*          /!* Card *!/*/}
          {/*          <a className="card" href="#">*/}
          {/*              <div className="card-body">*/}
          {/*                  <div className="row">*/}
          {/*                      <div className="col-md-4 mb-2 mb-md-0">*/}
          {/*                          <span className="h6">Développeur de logiciels (Full Stack)</span>*/}
          {/*                      </div>*/}
          {/*                      /!* End Col *!/*/}
          {/*                      <div className="col-sm-5 col-md-2 mb-2 mb-sm-0">*/}
          {/*                          <span className="text-body">Télétravail</span>*/}
          {/*                      </div>*/}
          {/*                      /!* End Col *!/*/}
          {/*                      <div className="col-sm-5 col-md-4 mb-2 mb-sm-0">*/}
          {/*                          <span className="text-body">Ventes</span>*/}
          {/*                      </div>*/}
          {/*                      /!* End Col *!/*/}
          {/*                      <div className="col-sm-2 text-sm-end">*/}
          {/*                          <span className="link link-pointer">Appliquer</span>*/}
          {/*                      </div>*/}
          {/*                      /!* End Col *!/*/}
          {/*                  </div>*/}
          {/*                  /!* End Row *!/*/}
          {/*              </div>*/}
          {/*          </a>*/}
          {/*          /!* End Card *!/*/}
          {/*          /!* Card *!/*/}
          {/*          <a className="card" href="#">*/}
          {/*              <div className="card-body">*/}
          {/*                  <div className="row">*/}
          {/*                      <div className="col-md-4 mb-2 mb-md-0">*/}
          {/*                          <span className="h6">Ingénieur Android</span>*/}
          {/*                          <span className="badge bg-soft-dark text-dark rounded-pill">*/}
          {/*          2 positions*/}
          {/*        </span>*/}
          {/*                      </div>*/}
          {/*                      /!* End Col *!/*/}
          {/*                      <div className="col-sm-5 col-md-2 mb-2 mb-sm-0">*/}
          {/*                          <span className="text-body">London</span>*/}
          {/*                      </div>*/}
          {/*                      /!* End Col *!/*/}
          {/*                      <div className="col-sm-5 col-md-4 mb-2 mb-sm-0">*/}
          {/*                          <span className="text-body">Développement de logiciels</span>*/}
          {/*                      </div>*/}
          {/*                      /!* End Col *!/*/}
          {/*                      <div className="col-sm-2 text-sm-end">*/}
          {/*                          <span className="link link-pointer">Appliquer</span>*/}
          {/*                      </div>*/}
          {/*                      /!* End Col *!/*/}
          {/*                  </div>*/}
          {/*                  /!* End Row *!/*/}
          {/*              </div>*/}
          {/*          </a>*/}
          {/*          /!* End Card *!/*/}
          {/*          /!* Card *!/*/}
          {/*          <a className="card" href="#">*/}
          {/*              <div className="card-body">*/}
          {/*                  <div className="row">*/}
          {/*                      <div className="col-md-4 mb-2 mb-md-0">*/}
          {/*                          <span className="h6">Responsable Stratégie Commerciale</span>*/}
          {/*                      </div>*/}
          {/*                      /!* End Col *!/*/}
          {/*                      <div className="col-sm-5 col-md-2 mb-2 mb-sm-0">*/}
          {/*                          <span className="text-body">San Francisco</span>*/}
          {/*                      </div>*/}
          {/*                      /!* End Col *!/*/}
          {/*                      <div className="col-sm-5 col-md-4 mb-2 mb-sm-0">*/}
          {/*                          <span className="text-body">Stratégie d'entreprise</span>*/}
          {/*                      </div>*/}
          {/*                      /!* End Col *!/*/}
          {/*                      <div className="col-sm-2 text-sm-end">*/}
          {/*                          <span className="link link-pointer">Appliquer</span>*/}
          {/*                      </div>*/}
          {/*                      /!* End Col *!/*/}
          {/*                  </div>*/}
          {/*                  /!* End Row *!/*/}
          {/*              </div>*/}
          {/*          </a>*/}
          {/*          /!* End Card *!/*/}
          {/*          /!* Card *!/*/}
          {/*          <a className="card" href="#">*/}
          {/*              <div className="card-body">*/}
          {/*                  <div className="row">*/}
          {/*                      <div className="col-md-4 mb-2 mb-md-0">*/}
          {/*                          <span className="h6">Concepteur de produits</span>*/}
          {/*                          <span className="badge bg-soft-dark text-dark rounded-pill">*/}
          {/*          7 positions*/}
          {/*        </span>*/}
          {/*                      </div>*/}
          {/*                      /!* End Col *!/*/}
          {/*                      <div className="col-sm-5 col-md-2 mb-2 mb-sm-0">*/}
          {/*                          <span className="text-body">San Francisco</span>*/}
          {/*                      </div>*/}
          {/*                      /!* End Col *!/*/}
          {/*                      <div className="col-sm-5 col-md-4 mb-2 mb-sm-0">*/}
          {/*                          <span className="text-body">Design</span>*/}
          {/*                      </div>*/}
          {/*                      /!* End Col *!/*/}
          {/*                      <div className="col-sm-2 text-sm-end">*/}
          {/*                          <span className="link link-pointer">Appliquer</span>*/}
          {/*                      </div>*/}
          {/*                      /!* End Col *!/*/}
          {/*                  </div>*/}
          {/*                  /!* End Row *!/*/}
          {/*              </div>*/}
          {/*          </a>*/}
          {/*          /!* End Card *!/*/}
          {/*      </div>*/}
          {/*      <div className="w-lg-65 text-center mx-lg-auto mt-7">*/}
          {/*          <p>*/}
          {/*              Ne vous inquiétez pas si vous ne voyez aucun rôle pour lequel vous souhaitez postuler pour le moment.*/}
          {/*              Enregistrez votre intérêt pour être informé de tout poste répondant à vos critères.*/}
          {/*          </p>*/}
          {/*          <a className="btn btn-primary" href="#">*/}
          {/*              Enregistrez votre intérêt*/}
          {/*          </a>*/}
          {/*      </div>*/}
                <div id='freshteam-widget' className="container content-space-t-lg-4"/>

            </div>
            {/* End List Directory */}
        </div>
        {/* ========== END MAIN CONTENT ========== */}
    </>

}

export default CareerContent;

