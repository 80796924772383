
import React, { useEffect } from "react";
import CareerContent from "./CareerContent";
import Layout from "./layout/Layout";

const useScript = (url: string) => {
  useEffect(() => {
    const script = document.createElement('script');
    if(url){
      script.src = url;
      script.async = true;
      document.body.appendChild(script);
    }

    return () => {
      document.body.removeChild(script);
    }
  }, [url]);
    return <Layout title={'Carrière'}>
        <CareerContent />
    </Layout>

};

export default useScript;
